.header {
  &Container {
    background-color: white;
    @include mq(sm){
      @include flexy($just: space-between, $dir: column);
    }	
  }

  &CompanyInfo {
    &Border {
      @include mq(sm){
        display: block;
      }

      display: none;
	    border-bottom: 1px solid darken($brand-color,7.5%);
    }

    &Container {
      @include flexy($dir: row-reverse);
      @include center();
      width: 100%;
    }

    &Wrap {
      @include flexy($dir: row);
    }

  }

  &Brand {
    &Container {
      @include center($w: 100%);
      @include flexy($just: space-between);
      padding: .5em;
      box-sizing: border-box;
    }

    &Name {
      @include mq(sm) {
        max-width: 100%;
      }
      margin: 0;
      font-weight: bold;
      color: $brand-color;
      max-width: 280px;
    }

    &Wrap {
      @include flexy($align: center);
      margin: 0;
      position: relative;
      z-index: placeOnLayer($layer: 'midFront');
      @include mq(sm) {
        margin: 0 .7em;
      }
      width: 100%;
    }
  }
  
  &MobileNav {
    @include mq(sm){
      position: static;
      width: auto;
      background-color: unset;
    }
    position: absolute;
    left: 0em;
    width: 100%;
    z-index: placeOnLayer($layer: 'middle');
    background-color: white;
    transition: top .5s ease;

    &Anchors {
      padding: .5em;
      display: inline-block;
      color: $brand-color;
      font-weight: bold;
      width: 100%;
      border-top: 1px solid #d4d4d4;
      @include mq(sm){
        width: auto;
        border-top: none;
      }
      
      &:hover {
        color: lighten($brand-color, 25%);
      }

      &:active {
        color: darken($brand-color, 25%);
      }
      @include mq (sm){
        padding: .5em;
      }
    }
  }


}


.mobileNavDropStart {
	top: -11em;
}

.mobileNavDropEnd {
	top: 2.75em;
}

#navBarLinks {
	border-bottom: 1px solid black;
	@include mq(sm){
		@include flexy($dir: row, $just: space-between);
		border-bottom: none;
	}
	
}
