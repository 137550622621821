.pageLayout {
  @include centerLayout();
  
}

.contactForm {
  @include flexy($just: center, $align: center, $dir: column);

  &Modal{
    &Container {
      @include mq('sm') {
        width: 300px;
      }
      z-index: placeOnLayer('front');
      text-align: center;
      position: absolute;
      background: $brand-color;
      color: white;
      font-size: 1.25em;
      width: 90%;
      left: 0;
      right: 0;
      margin: auto;
      top: -100px;
      opacity: 0;
      transition: all .75s ease-in-out;
    }

    &Show {
      top: 40%;
      opacity: 1;
    }
  }
}

#messageWrap {
  margin-bottom: 100px;
  position: relative;
}

.contactInput {
  max-width: 600px;
  width: 80%;
  margin-top: 1em;
  padding: .5em;
  box-sizing: border-box;
}

#contactSend {
  margin: 1em auto;
  padding: .5em;
  max-width: 600px;
  width: 80%;
  background-color: white;
  color: $brand-color;
  font-weight: bold;
  box-sizing: border-box;
}

#inputMessage {
  height: 150px;
}