.footer {
	&Container {
		@include flexy($just: center, $align: center, $dir: column);
		background-color: $brand-color;
		color: white;
	}
	
	&Top {
		@include centerLayout();
		
		&Wrap {
			@include mq('sm'){
				@include flexy($just: space-between, $align: center);
			}
			@include flexy($just: center, $align: center);
			height: 50px;
		}
	}

	&SocialLink {
		color: white;
		margin: auto 1em;
	}

	&Mid {
		@include centerLayout();
		border-top: 1px solid lighten($brand-color, 15%);

		&Wrap {
			@include mq('ml') {
				max-width: 100%;
			}
			@include flexy($just: space-around);
			max-width: 504px;
			height: 100%;
			flex-wrap: wrap;
			margin: auto;
		}

		&Square {
			@include flexy($align: center, $just:center);
			margin: 7px 10px;
		}

	}

	&LogoImg {
		width: 100%;
	}

	&Lower {
		@include centerLayout();
		@include flexy($just: center);
		border-top: 1px solid lighten($brand-color, 15%);
	}

	&LinksWrap {
		display: None;
		
		@include mq('sm'){
			@include flexy();
		}

	}

	&Link {
		margin-right: 1em
	}

	&PoliciesLink {
		&:visited{
			color: ghostwhite;
		}
		color: white;
		align-self: end;
		text-decoration: underline;
	}

}