.slider {
  &Container {
    position: relative;
    width:100%;
    height: 100%;
  }

  &Pic {
    transition: opacity .5s ease-in;
    width: 100%;
  }

  &PicTwo{
    position: absolute;
    top: 0px;
    left: 0px;
  }

  &PicFadeOut {
    opacity: .01;
  }

  &PicFadeIn {
    opacity: 1;
  }
}

.testContainer {
  background-color: tomato;
  height: 1000px;
}

.sliderWrap {
  background-color: steelblue;
  width: 50%;
}