//=================================
// Base Styles
//=================================


body, html{
	margin: 0 0;
	font-size: $font-size;
	height: 100%;
}

h1, h2, h3 {
	text-align: center;
}

p {
	margin-left: 15px;
	margin-right: 15px;
}

a{
	text-decoration: none;
}

hr {
	@include gradientBackground($dir: 280deg, $insetBlur: 0);
	height: .2em;
	width: 85%;
	display: inline-block;
	box-shadow: 1px 1px 2px black;
}