.headerInfoText {
  margin: .5em;
}

#siteBrandName {
	padding-top: .5em;
	padding-bottom: .5em;
    display: inline-block;
    @include mq (sm){
		padding: .5em;
    }
}

.footerLinkText {
  font-weight: bold;
  color: white;
}

.socialLink {
  color: white;
}