.pageTitle {
  font-size: 2.5em;
  font-weight: bold;
  color: white;
  margin: 0px 0px 0px 60px;

}


.defaultSectionImg {
  width: 100%;
}

.sectionFrame {
  @include flexy($dir: column, $align: center, $just: center);
  height: 450px;
  position: relative;
  &AboutImage {
    background: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("../../Assets/images/dreamstime_xxl_115286581.jpg");
    background-position: center;
    background-size: cover;
    align-items: flex-start;
  }
  &CalendarImage {
    background: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("../../Assets/images/dreamstime_xxl_82993668.jpg");
    background-position: center;
    background-size: cover;
    align-items: flex-start;
  }
  &ContactImage {
    background: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("../../Assets/images/dreamstime_xxl_118598959.jpg");
    background-position: center;
    background-size: cover;
    align-items: flex-start;
  }
  &CoursesImage {
    background: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("../../Assets/images/thumbnail_IMG_7573.jpg");
    background-position: center;
    background-size: cover;
    align-items: flex-start;
  }
  &HomeImage {
    background: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("../../Assets/images/dreamstime_xxl_95697704.jpg");
    background-position: center;
    background-size: cover;
  }
  &InnerImage {
    width: 100px;
    margin-bottom: 15px;
  }
  &PoliciesImage {
    background: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("../../Assets/images/dreamstime_xxl_95798469.jpg");
    background-position: center;
    background-size: cover;
    align-items: flex-start;
  }
}