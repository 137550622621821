.aboutContentWrap {
  margin: 50px;
}

.aboutMission {
  &Container {
    @include mq(ml){
      flex-direction: row;
    }
    @include flexy($dir: column-reverse);
  }

  &TextWrap {
    flex-basis: 50%;
  }

  &Text {
    @include mq(ml){
      text-align: left;
      margin-left: 30px;
    }
    text-align: center;
    line-height: 1.75em;
    font-size: 1.25em;
    font-weight: 500;
  }

  &ImageWrap {
    padding: 20px;
    flex-basis: 50%;
    align-self: center;
  }

  &Image {
    width: 100%;
  }

}

.aboutCourse {
  @include flexy($dir: column, $just: space-between);
  @include mq('xs') {
    flex-basis: 90%;
  }
  @include mq('sm') {
    min-width: 350px;
  }
  @include mq('md') {
    @include flexy($dir: column, $just: space-between);
  }
  @include mq('ml') {
    @include flexy($dir: row, $just: space-between);
  }
  text-align: center;
  background-color: whitesmoke;
  margin: 0px 15px 30px;
  padding: 25px;

  &HeaderText {
    margin-top: 100px;
  }

  &Container {
    @include flexy($just: center, $align: center)
  }

  &Wrap {
    @include flexy($dir: row, $just: center, $wrap:wrap);
    margin: 50px 0px;
  }

  &AgencyLogo {
    @include mq('ml') {
      @include flexy($dir: column, $just: center);
    }
  }

  &AgencyText {
    @include flexy($dir: column, $just: space-between);
    line-height: 1.25em;
    font-size: 1.25em;
    color: black;
    margin: 15px 0px;
  }

  &format {
    margin: 0px 30px 10px;
  }

}

.aboutPoliciesLink{
  color: black;
  text-decoration: underline;
  font-size: 1.5em;

  &Wrap {
    @include flexy($just: center, $align: center);
  }
}
