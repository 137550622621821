//typography
$font-size: 16px;

//colors
$color-primary: #4d99d3;
$color-secondary: #4dd387;
$color-accent: #cc9f79;

$brand-color: #d32f2f;

//Layout
$max-width: 1240px;
$gutter: 10px;

//Breakpoints
$breakpoints: (
	'xs': 575px,
	'sm': 576px,
	'md': 630px,
	'ml': 750px,
	'lg': 992px
);

//Z-Index levels
$indexLayers: (
	'back': 0,
	'midBack': 1,
	'middle': 25,
	'midFront': 50,
	'front': 100
);