//Media Queries
@mixin mq($break) {
	$value: map-get($breakpoints, $break);
	$sm: map-get($breakpoints, 'sm');

	@if $value == null {
		@error "#{$break} is an invalid breakpoint name";
	}
	@else if $value < $sm {
		@media (max-width: $value) {
			@content;
		}
	}
	@else {
		@media (min-width: $value) {
			@content;
		}
	}
}

//Center
@mixin center($w: 90%, $max: $max-width, $marginL: auto, $marginR: auto) {
	width: $w;
	max-width: $max;
	margin-left: $marginL;
	margin-right: $marginR;
}

//Center Layout
@mixin centerLayout($w: 100%, $max: $max-width, $marginL: auto, $marginR: auto) {
	width: $w;
	max-width: $max;
	margin-left: $marginL;
	margin-right: $marginR;
}

//Flex Containers
@mixin flexy(
	$disp: flex,
	$dir: null,
	$wrap: null,
	$just: null,
	$align: null ){
	display: $disp;
	flex-direction: $dir;
	flex-wrap: $wrap;
	justify-content: $just;
	align-items: $align;
}

//theme colored background gradient used throughout site
@mixin gradientBackground(
	$dir: 0deg,
	$alpha: null,
	$insetBlur: 1.5em,
	$backImg: null
	){
	background-color: white;
	background: linear-gradient($dir, addAlpha(lighten($brand-color, 10%), $alpha), addAlpha(darken($brand-color,10%), $alpha)), $backImg;
	box-shadow: inset 0px 0px $insetBlur white;
}