.courses {
  background-color: #f1f1f1;

  &Img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 50px;
  }

  &Container {
    @include flexy($just: center, $align: center, $dir: column);
     padding: 50px 20px;
  }

  &Wrapper {
    @include flexy($dir: column)
  }

  &AgencyWrapper {
    max-width: 800px;
    border: 1px solid black;
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 25px;
    background-color: white;
  }

  &AgencyNameHeader {
    margin-top: 0;
    color: $brand-color;
    cursor: pointer;
    &:hover{
      color: lighten($brand-color, 15);
    }
  }

  &CategoriesContainer {
    height: auto;
    overflow: hidden;
    transition: all ease-in-out 1s;
  }

  &ClassTitle {
    margin-bottom: 5px;
  }


  &Details {
    display: none;
    margin-left: 10px;

    &Button {
      border: none;
      background: white;
      text-decoration: underline;
      cursor: pointer;
      color: blue;
      margin-bottom: 5px;
    }

    &List {
      margin: 5px auto;
    }

    &Wrap {
      margin-left: 10px;
    }

  }


  &ShowDetails {
    display: block;
  }
}

.collapse {
  height: 0px;
}