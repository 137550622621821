.singleDay {
    &Button {
        background-color: lighten(chocolate, 7%);
        border: 1px solid darken(chocolate, 3%);
        box-shadow: 1px 2px 3px darken(chocolate, 6%);
        border-radius: 50px;
        color: white;
        margin: 0px 10px;
        padding: 8px 15px;
        transition: ease-in-out all .25s;
    }

    &Button:hover {
        box-shadow: 2px 4px 5px darken(chocolate, 6%);
    }

    &Button:active {
        background-color: lighten(chocolate, 2%);
        box-shadow: 2px 4px 5px darken(chocolate, 6%);
    }

    &Class {
        &Text {
            margin: 0px 0px 2px;
        }

        &Container {
            padding: 0px 6px;
            margin: 15px 0px;
        }

        &Container:hover{
            background-color: lighten(chocolate, 7%);
        }
    }

    &Container {
        @include flexy($just: center, $align: center, $dir: column);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    &FooterContainer {
        @include flexy($just: center, $align: center);
        padding: 0px 6px;
        margin: 15px 0px;
    }

    &Header {
        &Container {
            padding: 6px 6px 0px;
        }

        &Icon {
            font-size: 20px;
            margin-left: 10px;
        }


        &UpperWrap {
            @include flexy($just: space-between, $align: center);
        }

        &Text{
            margin: 0px;
        }
    }

    &Wrap {
        background-color: chocolate;
        color: white;
        border: 2px solid white;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.575);
    }
}