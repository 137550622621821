.homeGist {
  &Container {
    @include mq(ml){
      flex-direction: row;
    }

    @include flexy($just: center, $align: center, $dir: column);
    margin: 100px auto;
  }

  &SectionWrap {
    @include mq(xs){
      padding: 10px 15px;
    }
    @include mq(ml){
      padding: 30px;
    }
    padding: 10px 70px;
    flex-basis: 50%;
  }

  &Header {
    margin: 0px;
    color: $brand-color;
    font-size: 1.75em;
    font-family: serif;
  }
  
  &Text {
    margin: 0px;
    font-size: 1.25em;
    line-height: 1.5em;
  }
}

.homeTestimonials {
  &Container {
    margin-bottom: 100px;
  }

  &HeaderWrap {
    @include mq(xs){
      padding: 0px 15px;
    }
    @include mq(md) {
      padding: 0px 20px;
    }
    @include mq(ml){
      padding: 0px;
    }
    padding: 0px 15px;
    @include flexy($dir: row, $align: center, $just: flex-start);
    margin-bottom: 25px;
  }

  &Header {
    display: inline;
    font-size: 1.25em;
    margin: 0px 15px 0px 0px;
  }

  &QuoteWrap {
    @include mq(xs){
      padding: 0px 15px;
    }
    @include mq(md) {
      padding: 0px 20px;
    }
    @include mq(ml){
      padding: 0px 30px;
    }
    padding: 0px;
    transition: opacity .3s ease-in;
  }

  &Quote {
    font-size: 1.5em;
    font-family: serif;
  }

  &Author {
    margin-left: 7px;
  }

  &Company {
    margin-left: 7px;
  }
}

.fadeIn {
  opacity: 1;
}

.fadeOut {
  opacity: 0;
}