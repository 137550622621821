.calendar {
  &Container {
    @include mq('xs') {
      overflow-x: scroll;
    }
    color: white;
    background: chocolate;
    border: 1px solid white;
    margin: 40px auto;
  }

  &HeaderText {
    margin: 2px 7px 5px;
    font-size: 12.75px;
  }

  &Text {
    margin: 2px 7px;
    font-size: 12.75px;
  }

  &HeaderWrap {
    @include flexy($dir: row, $just: space-between, $align: center);
  }

  &Wrap {
    &Inner {
      @include flexy($dir: column, $wrap: wrap);
    }

    &Outter {
      min-width: 575px;
      position: relative;
    }
  }
}

.dayWrap {
  height: 100px;
  position: relative;

  &Container {
    @include flexy($dir: row);
  }

  &Cursor {
    cursor: pointer;
  }

  &Format {
    flex-basis: 14.12%;
    flex-grow: 1;
    border: 1px solid white;
    overflow: hidden;
  }

  &Header {
    @include flexy($just: center, $align: center);

    &TextFull {
      @include mq("md") {
        display: block;
      }
      display: none;
      margin: 15px 0px;

    }

    &TextAbrv {
      @include mq("md") {
        display: none;
      }
      display: block;
      margin: 15px 0px;
    }
  }

  &Overflow {
    position: absolute;
    background: chocolate;
    bottom: 0;
    width: 100%;
    margin: 0;
    text-align: center;
    transition: all .5s ease-in-out;
  }

  &OverflowFade {
    opacity: 0;
  }
}

.eventWrapper {
  margin: 0px 0px 6px;
}

.month {
  &SelectIcon {
    font-size: 35px;
    &Container {
      margin: 0px 15px;
      font-size: 25px;
      visibility: hidden;
      @include flexy($dir: row, $align: center)
    }
  }
}

.weekWrap {
  @include flexy($dir: row)
}