
.homeHeader {
  &PageTitle {
    @include mq(md){
      font-size: 2em;
    }

    @include mq(ml){
      font-size: 2.5em;
    }
    font-size: 1.5em;
    font-weight: bold;
    font-family: serif;
    color: white;
    margin: 0px 0px 25px 0px ;
  }

  &Frame {
    @include flexy($dir: column, $align: center, $just: center);
    height: 450px;
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)), url("../../Assets/images/dreamstime_xxl_95697704.jpg");
    background-position: center;
    background-size: cover;
  }

  
  &FrameInnerImage {
    width: 100px;
    margin-bottom: 25px;
  }

  &CallToAction {
    &ButtonWrap {
      @include mq(sm){
        flex-direction: row;
      }
      @include flexy($just: space-evenly, $align: center, $dir: column);
    }
  
    &Button {
      @include mq(sm){
        margin: 0px 0px;
      }
      margin: 10px 0px;
      height: 40px;
      width: 150px;
      color: #dbdbdb;
      font-weight: bold;
      border: none;
      border-radius: 3px;
      background-color: $brand-color;
      transition: background-color .5s;
  
      &:focus {
        outline: none;
        animation: clicked .5s linear;
      }
  
      &:hover {
        color: white;
      }
    }
  }
}

@keyframes clicked {
  from {
    background-image: radial-gradient(darken($brand-color, 8%) 5%, lighten($brand-color, 8%));
  }

  to {
    background-image: radial-gradient(darken($brand-color, 8%), darken($brand-color, 8%));
  }
}
