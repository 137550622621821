
.siteBrand {
	height: 2em;
	margin-bottom: -.2em;
}

#menuIcon {
	position: absolute;
	top: .5em;
	right: .5em;
	@include mq(sm){
		display: none;
	}
	&:hover {
		text-shadow: 1px 0px 4px white;
	}
}