.contactOptionsContainer {
  @include flexy($just: center);
  @include mq('xs') {
    flex-direction: column;
    align-items: center;
    padding: 5px 10px;
  }
  padding: 5px 50px;
  box-sizing: border-box;
}

.contactOption {
  @include flexy($dir: column, $just: center);
  @include mq('xs') {
    width: 100%;
    margin: 10px auto;
    padding: unset;
  }
  @include mq('sm') {
    margin: 35px 15px;
  }
  padding: 5px;
  width: 33%;
  margin: 35px;
  transition: box-shadow .5s linear;
  color: white;
  background: $brand-color;
}

.contactOption:hover {
  box-shadow: 0px 6px 8px #0000005e;
}


.contactOptionHeader {
  text-align: center;
}

.contactOptionText {
  margin: 0px 16px 18px;
}

.contactIcon {
  font-size: 2.25em;
  text-align: center;
  margin-top: 16px;
  color: white;
}