.policies{
  &Container{
    @include center()
  }
}

.policy{
  &Wrapper {
    max-width: 700px;
    margin: 0px auto 30px;
  }
}