//general styles start

.pageLayout {
	@include centerLayout();
}

.mockUp {
	@include centerLayout();
	@include flexy($just: center, $align: center);
	min-height: 300px;
}

//general styles end
